@charset "UTF-8";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@font-face {
  font-family: "ui-icons";
  src: url("../fonts/ui-icons.ttf?da52fc") format("truetype"), url("../fonts/ui-icons.woff?da52fc") format("woff"), url("../fonts/ui-icons.svg?da52fc#ui-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ui-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon.mat-icon {
  font-size: unset;
  width: unset;
  height: unset;
}

.icon-zip:before {
  content: "";
}

.icon-font:before {
  content: "";
}

.icon-sketch:before {
  content: "";
}

.icon-adobe-pdf:before {
  content: "";
}

.icon-illustrator:before {
  content: "";
}

.icon-photoshop:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-rocket:before {
  content: "";
}

.icon-list-view:before {
  content: "";
}

.icon-person-circle:before {
  content: "";
}

.icon-add:before {
  content: "";
}

.icon-apps:before {
  content: "";
}

.icon-arrow-back:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-forward:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-renew:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-close:before {
  content: "";
}

.icon-arrows-compare:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-delete:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-caret-down:before {
  content: "";
}

.icon-caret-left:before {
  content: "";
}

.icon-caret-right:before {
  content: "";
}

.icon-caret-up:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-more-horiz:before {
  content: "";
}

.icon-more-vert:before {
  content: "";
}

.icon-notifications:before {
  content: "";
}

.icon-person:before {
  content: "";
}

.icon-repeat:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-storage:before {
  content: "";
}

.icon-thumb-down:before {
  content: "";
}

.icon-thumb-up:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-widgets:before {
  content: "";
}

.icon-remove:before {
  content: "";
}

.icon-replay:before {
  content: "";
}

.icon-next-week:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-sun:before {
  content: "";
}

.icon-today:before {
  content: "";
}

.icon-save:before {
  content: "";
}

.icon-sort:before {
  content: "";
}

.icon-sort-alpha:before {
  content: "";
}

.icon-person-box:before {
  content: "";
}

.icon-task:before {
  content: "";
}

.icon-person-task:before {
  content: "";
}

.icon-paperclip:before {
  content: "";
}

.icon-exit:before {
  content: "";
}

.icon-people:before {
  content: "";
}

.icon-people-add:before {
  content: "";
}

.icon-groups:before {
  content: "";
}

.icon-portrait:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-fire:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-dns:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-location:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-people-outline:before {
  content: "";
}

.icon-person-pin:before {
  content: "";
}

.icon-list-check:before {
  content: "";
}

.icon-person-super:before {
  content: "";
}

.icon-person-folder:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-inbox-empty:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-photo:before {
  content: "";
}

.icon-person-calendar:before {
  content: "";
}

.icon-person-add:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-audio:before {
  content: "";
}

.icon-brush:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-align-left:before {
  content: "";
}

.icon-color-text:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-file:before {
  content: "";
}

.icon-pdf:before {
  content: "";
}

.icon-pie-chart:before {
  content: "";
}

.icon-code:before {
  content: "";
}

/* products (local_offer)*/
.icon-products:before {
  content: "";
}

/* conditions (local_atm)*/
.icon-conditions:before {
  content: "";
}

/* orders (documents)*/
.icon-orders:before {
  content: "";
}

/* suppliers (local_shipping)*/
.icon-suppliers:before {
  content: "";
}

/* costs (attach_money)*/
.icon-costs:before {
  content: "";
}

.icon-visibility:before {
  content: "";
}

.icon-visibility-off:before {
  content: "";
}

.icon-locked:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-shopping-cart:before {
  content: "";
}

.icon-shopping-basket:before {
  content: "";
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: #eceff1;
}

body {
  height: 100%;
  background-color: #eceff1;
}
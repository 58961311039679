@import './icons';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: #eceff1;
}

body {
  height: 100%;
  background-color: #eceff1;
}

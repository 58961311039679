@font-face {
  font-family: 'ui-icons';
  src: url('../fonts/ui-icons.ttf?da52fc') format('truetype'), url('../fonts/ui-icons.woff?da52fc') format('woff'),
    url('../fonts/ui-icons.svg?da52fc#ui-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ui-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon.mat-icon {
  font-size: unset;
  width: unset;
  height: unset;
}

.icon-zip:before {
  content: '\e907';
}
.icon-font:before {
  content: '\e906';
}
.icon-sketch:before {
  content: '\e902';
}
.icon-adobe-pdf:before {
  content: '\e903';
}
.icon-illustrator:before {
  content: '\e904';
}
.icon-photoshop:before {
  content: '\e905';
}
.icon-play:before {
  content: '\e039';
}
.icon-briefcase:before {
  content: '\e9ae';
}
.icon-rocket:before {
  content: '\e900';
}
.icon-list-view:before {
  content: '\e8ef';
}
.icon-person-circle:before {
  content: '\e853';
}
.icon-add:before {
  content: '\e145';
}
.icon-apps:before {
  content: '\e5c3';
}
.icon-arrow-back:before {
  content: '\e5c4';
}
.icon-arrow-down:before {
  content: '\e5db';
}
.icon-arrow-forward:before {
  content: '\e5c8';
}
.icon-arrow-up:before {
  content: '\e5d8';
}
.icon-renew:before {
  content: '\e863';
}
.icon-check:before {
  content: '\e5ca';
}
.icon-close:before {
  content: '\e5cd';
}
.icon-arrows-compare:before {
  content: '\e915';
}
.icon-edit:before {
  content: '\e254';
}
.icon-dashboard:before {
  content: '\e871';
}
.icon-delete:before {
  content: '\e872';
}
.icon-home:before {
  content: '\e88a';
}
.icon-link:before {
  content: '\e157';
}
.icon-caret-down:before {
  content: '\e313';
}
.icon-caret-left:before {
  content: '\e314';
}
.icon-caret-right:before {
  content: '\e315';
}
.icon-caret-up:before {
  content: '\e316';
}
.icon-menu:before {
  content: '\e5d2';
}
.icon-more-horiz:before {
  content: '\e5d3';
}
.icon-more-vert:before {
  content: '\e5d4';
}
.icon-notifications:before {
  content: '\e7f4';
}
.icon-person:before {
  content: '\e7fd';
}
.icon-repeat:before {
  content: '\e040';
}
.icon-search:before {
  content: '\e8b6';
}
.icon-settings:before {
  content: '\e8b8';
}
.icon-storage:before {
  content: '\e1db';
}
.icon-thumb-down:before {
  content: '\e8db';
}
.icon-thumb-up:before {
  content: '\e8dc';
}
.icon-key:before {
  content: '\e0da';
}
.icon-widgets:before {
  content: '\e1bd';
}
.icon-remove:before {
  content: '\e15b';
}
.icon-replay:before {
  content: '\e042';
}
.icon-next-week:before {
  content: '\e16a';
}
.icon-calendar:before {
  content: '\eb17';
}
.icon-sun:before {
  content: '\eb9d';
}
.icon-today:before {
  content: '\e8df';
}
.icon-save:before {
  content: '\e161';
}
.icon-sort:before {
  content: '\e164';
}
.icon-sort-alpha:before {
  content: '\e053';
}
.icon-person-box:before {
  content: '\e851';
}
.icon-task:before {
  content: '\e85d';
}
.icon-person-task:before {
  content: '\e85e';
}
.icon-paperclip:before {
  content: '\e226';
}
.icon-exit:before {
  content: '\e879';
}
.icon-people:before {
  content: '\e7fb';
}
.icon-people-add:before {
  content: '\e7f0';
}
.icon-groups:before {
  content: '\e886';
}
.icon-portrait:before {
  content: '\e416';
}
.icon-share:before {
  content: '\e80d';
}
.icon-fire:before {
  content: '\e80e';
}
.icon-phone:before {
  content: '\e0cd';
}
.icon-chat:before {
  content: '\e0b7';
}
.icon-download:before {
  content: '\e2c0';
}
.icon-dns:before {
  content: '\e875';
}
.icon-mail:before {
  content: '\e159';
}
.icon-location:before {
  content: '\e8b4';
}
.icon-inbox:before {
  content: '\e168';
}
.icon-people-outline:before {
  content: '\e7fc';
}
.icon-person-pin:before {
  content: '\e55a';
}
.icon-list-check:before {
  content: '\e065';
}
.icon-person-super:before {
  content: '\e8d3';
}
.icon-person-folder:before {
  content: '\e2c9';
}
.icon-lock:before {
  content: '\e897';
}
.icon-inbox-empty:before {
  content: '\e156';
}
.icon-list:before {
  content: '\e896';
}
.icon-photo:before {
  content: '\e410';
}
.icon-person-calendar:before {
  content: '\e8a3';
}
.icon-person-add:before {
  content: '\e7fe';
}
.icon-check-circle:before {
  content: '\e86c';
}
.icon-audio:before {
  content: '\e3a1';
}
.icon-brush:before {
  content: '\e3ae';
}
.icon-camera:before {
  content: '\e412';
}
.icon-align-left:before {
  content: '\e236';
}
.icon-color-text:before {
  content: '\e23c';
}
.icon-grid:before {
  content: '\e3ec';
}
.icon-file:before {
  content: '\e24d';
}
.icon-pdf:before {
  content: '\e415';
}
.icon-pie-chart:before {
  content: '\e6c4';
}
.icon-code:before {
  content: '\e86f';
}
/* products (local_offer)*/
.icon-products:before {
  content: '\e54e';
}
/* conditions (local_atm)*/
.icon-conditions:before {
  content: '\e53e';
}
/* orders (documents)*/
.icon-orders:before {
  content: '\e9ab';
}
/* suppliers (local_shipping)*/
.icon-suppliers:before {
  content: '\e558';
}
/* costs (attach_money)*/
.icon-costs:before {
  content: '\e227';
}
.icon-visibility:before {
  content: '\e8f4';
}
.icon-visibility-off:before {
  content: '\e8f5';
}
.icon-locked:before {
  content: '\eb61';
}
.icon-filter:before {
  content: '\e152';
}
.icon-shopping-cart:before {
  content: '\ea31';
}
.icon-shopping-basket:before {
  content: '\ea30';
}
